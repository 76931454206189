<template>
    <component v-if="route.meta.guard !== GUARD.LINK" :is="layout">
        <router-view :key="reloadKey"></router-view>
    </component>
    <template v-else>
        <router-view></router-view>
    </template>
    <base-notification />
</template>

<script setup>
import { GUARD } from '@tenant/utils/constants'
import posthog from 'posthog-js'
import { isEmpty } from 'lodash-es'
const { centralUser } = useAuth()
watch(
    () => centralUser.value,
    (value) => {
        if (!isEmpty(value)) {
            posthog.identify(value.id, {
                email: value.email,
                first_name: value.first_name,
                last_name: value.last_name,
            })
        }
    },
    {
        immediate: true,
    }
)

const reloadKey = ref(new Date().getTime())
const emitter = useEmitter()
const route = useRoute()

const layout = computed(() => route.meta.layout)

useHead({
    titleTemplate: `%s | ${import.meta.env.VITE_APP_NAME}`,
})

onMounted(() => {
    emitter.on('set-reload', setReload)
})

onBeforeUnmount(() => {
    emitter.off('set-reload', setReload)
})

const setReload = () => {
    reloadKey.value = new Date().getTime()
}
</script>
